<template>
	<v-card>
		<v-card-text>
			<p>
				You are a current assessment lead or coordinator for this organisation
			</p>
			<p>
				You can nominate someone else for this role instead below
			</p>
			<add-colleague v-model="newUser"></add-colleague>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn icon color="primary" @click="sure = true">
				<v-icon>mdi-chevron-right-circle</v-icon>
			</v-btn>
		</v-card-actions>
		<are-you-sure
			:value="sure"
			@input="submit"
			text="Once you do this, you will lose access to the assessment"
		></are-you-sure>
	</v-card>
</template>
<script>
	import AreYouSure from "@c/notices/AreYouSure";
	import AddColleague from "@c/collaboration/AddColleague.vue";
	export default {
		name: "ChangeLeadRespondent",
		data: () => {
			return {
				newUser: "",
				sure: false
			};
		},
		components: {
			AreYouSure,
			AddColleague
		},
		computed: {
			user() {
				return this.$store.getters["auth/id"];
			}
		},
		methods: {
			submit() {
				let users = {
					[this.newUser]: "leadRespondent",
					[this.user]: "user"
				};
				this.$store.dispatch("emails/leadRespondent", this.newUser);
				this.$store.dispatch("assessment/patch", {users}).then(() => {
					this.$router.replace("/");
				});
			}
		}
	};
</script>
