<template>
    <mw-dialog
        :value="dialog"
        @input="$emit('cancel')"
        small
        submitBtn
        :title="$t('misc.are_you_sure')"
        @submit="$emit('input')"
    >
        <template v-slot:dialog-body>
            <p>{{  text }}</p>
        </template>
    </mw-dialog>


</template>

<script>
    import MwDialog from "@c/ui/MwDialog"
    export default {
        name: "AreYouSure", 
        props: {
            value: { type: [Boolean, String]},
            text: {}
        },
        data: () => {
            return {
                dialog: null
            }
        },
        watch: {
            value(v) {
                if( v ){
                    this.dialog = true;
                }
            }
        },
        components: {
            MwDialog
        }
    }
</script>