<template>
	<v-card class="mb-2">
		<v-card-text>
			<b>
				Your Approver Is:
			</b><br/>
				{{approvers.map( a => a.name).join(",")}}
		</v-card-text>
	</v-card>
</template>
<script>
	export default {
		name: "YourApprover",
		data: () => {
			return {
				newUser: "",
				sure: false
			};
		},
		components: {
		},
		computed: {
			organisation() {
				return this.$store.state.organisation.data
			},
			users() {
				return this.$store.state.users.data
			}, 
			approvers() {
				const self = this;
				let approvers = this.organisation.approvers || [];
				return approvers.map( a => self.users[a] );
			}


		},
		methods: {
		}
	};
</script>
