<template>
	<v-sheet>
		<h2 class="mb-5">Organisation Profile</h2>
		<v-row>
			<v-col>
				<v-text-field
					large
					:label="$t('organisation.name')"
					v-model="organisation.name"
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="9">
				<v-row class="mb-8">
					<v-col>
						<p>
							You should select all services provided by the organisation for
							which you are completing the assessment, irrespective of whether
							those services are provided by your organisation directly,
							provided by a contracted third party or provided by another
							organisation with whom you are providing a joint assessment. For
							more information, please see the section on Assessment Setup
							<a href="/help/tutorials">here</a>.
						</p>
						<toggle-category
							v-model="organisation['service']"
							:include="serviceOptions"
							id="service"
							:disable="disableServiceChange"
							storeModule="services"
							multiple
						></toggle-category>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" md="6">
						<v-textarea
							rows="4"
							:label="$t('organisation.address')"
							v-model="organisation.address"
						></v-textarea>
						<v-text-field
							:label="$t('organisation.city')"
							v-model="organisation.city"
						></v-text-field>
						<v-text-field
							:label="$t('organisation.postcode')"
							v-model="organisation.postcode"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="6">
						<v-text-field
							append-icon="mdi-phone"
							:label="$t('organisation.phone')"
							v-model="organisation.phone"
						></v-text-field>
						<v-text-field
							append-icon="mdi-web"
							:label="$t('organisation.url')"
							v-model="organisation.url"
						></v-text-field>
					</v-col>
				</v-row>
				<v-row>
				<v-col>
					<v-text-field type="number" v-model="organisation.fte" label="How many full-time equivalent staff (FTEs) does your organisation employ in Scotland?"></v-text-field>
				</v-col>
				</v-row>
				<p class="text-right">
					<v-btn @click="save" color="primary">{{ $t("buttons.save") }}</v-btn>
				</p>
			</v-col>
			<v-col cols="12" md="3">
				<organisation-stats></organisation-stats>
				<your-approver></your-approver>
				<change-lead-respondent></change-lead-respondent>
			</v-col>
		</v-row>
		<mw-dialog v-model="flag" small submitBtn @submit="flag = false">
			<template v-slot:dialog-body>
				<p>{{ $t("organisation.correct_services") }}</p>
			</template>
		</mw-dialog>
		<v-snackbar color="primary" v-model="saved" text>{{
			$t("misc.saved")
		}}</v-snackbar>
	</v-sheet>
</template>
<script>
	import OrganisationStats from "@c/profile/OrganisationStats";
	import YourApprover from "@c/profile/YourApprover";
	import ChangeLeadRespondent from "@c/profile/ChangeLeadRespondent";
	import ToggleCategory from "@c/ui/ToggleCategory.vue";
	import MwDialog from "@c/ui/MwDialog.vue";
	export default {
		name: "OrganisationProfile",
		props: {
			flag: {type: Boolean}
		},
		data: () => {
			return {
				saved: false,
				disableServiceChange: false
			};
		},
		computed: {
			organisation() {
				return this.$store.state.organisation.data;
			},
			provider() {
				const id = this.organisation.provider[0];
				if( !id ){
					return null;
				}
				return this.$store.state.categoryOptions.data[id]
			},
			serviceOptions() {
				return this.provider ? this.provider.services : []
			}
		},
		components: {
			MwDialog,
			ToggleCategory,
			OrganisationStats,
			ChangeLeadRespondent,
			YourApprover
		},
		methods: {
			save() {
				const self = this;
				const update = {
					service: self.organisation["service"] || [],
					name: self.organisation.name || "",
					address: self.organisation.address || "",
					city: self.organisation.city || "",
					postcode: self.organisation.postcode || "",
					phone: self.organisation.phone || "",
					url: self.organisation.url || "",
					fte: self.organisation.fte || 0
				};
				self.$store.dispatch("organisation/patch", update).then(() => {
					self.saved = true;
				});
			}
		},
		created() {
			this.$store.dispatch("assessment/fetchData");
			if (this.organisation.service.length) {
				this.disableServiceChange = true;
			}
		}
	};
</script>
