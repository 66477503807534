<template>						
    <div>
        <p>
            <span class="text-h6" v-if="title">{{title}}</span>
            <v-tooltip top v-if="tooltip">
                <template v-slot:activator="{on}">
                    <v-icon class="ml-3" v-on="on">mdi-information-outline</v-icon>
                </template>
                {{tooltip}}
            </v-tooltip>
        </p>
        <v-item-group :value="value" @change="output" :multiple="multiple">
            <v-item
                v-slot="{ toggle, active }"
                v-for="option in options"
                :key="option.id"
                :value="option.id"
            >
                <v-chip
                    class="pa-4 ma-1 white--text"
                    :disabled="disable"
                    @click="toggle" 
                    :color="active ? 'primary' : 'grey'"
                    >
                    {{ option.name }}</v-chip
                >
            </v-item>
        </v-item-group>
    </div>
</template>

<script>
export default {
	name: "ToggleCategory",
	props: {
        value: {type: [String, Array] },
        include: {type: [String, Array], default: () => ([]) },
        multiple: {type: Boolean, default: false},
		id: { type: String },
		title: { type: String },
		tooltip: { type: String },
        disable: {type: Boolean },
        storeModule: { type: String }

	},
	data: () => {
		return {
		};
	},
	computed: {
		category() {
			return this.$store.state[this.storeModule].data				
		},
        options(){
            const self = this;
            if( !this.category || !this.category.options ){
                return [];
            }
            let options = this.category.options;
            if( this.include.length ){
                options = this.include;
            }
            options = options.map( o => self.$store.state.categoryOptions.data[o] ).filter( o => o);
            return options;
        }
	},
    methods: {
        output(value){
            this.$emit("input", value )
        }
    }
};
</script>
